.Homepage {
  /* put around the vertical center of page */
  height: calc(100vh - 150px);
  display: flex;
  align-items: center;
}

.Homepage-background {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100vw;
  left: 0;
  z-index: -1;
  background-image: url("./friend-group.jpg");
  background-size: cover;
  background-position: center center;
  color: #fff;
  text-align: center;
  padding: 1rem;
  text-shadow: 0 0 8px #66757f;
}
