.LoginForm label {
    font-weight: bold;
}

.LoginForm .card {
    display: flex;
    flex-direction: column;
    color: inherit;
    border-radius: 0;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}