.PotentialFriendsList-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
  
.PotentialFriendsList-main > i {
  cursor: pointer;
  margin: 10px;
  color: #aaa;
}

.PotentialFriendsList-main > i.hidden {
  opacity: 0;
  pointer-events: none;
}

.PotentialFriendsList-main > i:hover {
  color: black;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}